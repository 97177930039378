<script lang="ts">
    import MelImage from "@/components/charts/MelImage.svelte";
    import { onMount } from "svelte";
    import { type Race, convertSecondsToStopwatch } from "@/data/prefabs/Race.js";

    export let popupData;
    export var race : Race;

    let melNames: (string | undefined)[] = [];
    let raceData = race.realtimeData;

    enum PopupClass {
        finish = 'finishPopup',
        dq = 'DQPopup'
    }

    const getMelNames = () => {
        if (raceData)
            melNames = raceData.map(data => data?.entity.melJSONConfig?.aesthetic_name);
    };

    onMount( ()=>{
        getMelNames();
    });
</script>

<div id={popupData.order} class={popupData.popupClass}>
    <div class="popupText">
        {#if popupData.popupClass === PopupClass.finish}
            {convertSecondsToStopwatch(popupData.finishTime)}
        {:else}
            <span class="dqText">DQ</span>
        {/if}
    </div>
    <div class="popupAvatar">
        <MelImage imageName={melNames[popupData.imageIndex]} imagetype="sports"></MelImage>
    </div>
</div>

<style>
    .dqText {
        color: #e50000;
    }

    #firstPopup {
        bottom: 170px;
    }

    #secondPopup {
        bottom: 220px;
    }

    #firstPopup, #secondPopup {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        right: 0;
        height: 35px;
        padding-left: 40px;
        background: linear-gradient(105deg, transparent 30px, rgba(0,0,0,0.65) 30px);
        background-repeat: no-repeat;
        background-size: cover;

        animation: slideInFromRight 0.5s forwards;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1); /* ease-out for slide-in */
        transform: translateX(100%);
    }

    .popupAvatar {
        width: 35px;
        height: 35px;
        background-color: rgb(226,226,226, 1.0);
        overflow: hidden;
    }

    .popupText {
        padding-right: 15px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        text-wrap: wrap;
        display: flex;
        align-items: center;
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
</style>