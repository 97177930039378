<script lang="ts">
    import TopNav from '@/elements/Navigation/TopNav.svelte';
    import SandboxEntry from '@/elements/Sandboxes/SandboxEntry.svelte';
    import DevPlaygrounds from '@/data/playgrounds/sandboxes-dev.json';
    import { ReleasePlaygrounds } from '@/data/playgrounds/sandboxes-release';
    import { onMount, createEventDispatcher } from 'svelte';

    let isSandboxesActive = true; // initial state
    let isMelympicsActive = false;
    let sandboxesList: SandboxData[] = [];
    let melympicsList: SandboxData[] = [];
    let page = 'overlay';
    const dispatch = createEventDispatcher();

    if (window.location.pathname === '/sandboxes')
        page = 'page'

    let sandboxes : SandboxData[] = [];
    onMount(async () => {
        sandboxes = ReleasePlaygrounds;
        if (IS_DEV) {
            sandboxes = [...sandboxes, ...DevPlaygrounds];
        }
        sandboxesList = sandboxes.filter(item => item.category !== 'melympics');
        melympicsList = sandboxes.filter(item => item.category === 'melympics');

        const urlParams = new URLSearchParams(window.location.search);
        const currentSandboxId = urlParams.get('p');
        if (melympicsList.some(sandbox => sandbox['name'] === currentSandboxId)) {
            isSandboxesActive = false;
            isMelympicsActive = true;
        }
	});

    function showSandboxes() {
        isSandboxesActive = true;
        isMelympicsActive = false;
    }

    function showMelympics() {
        isSandboxesActive = false;
        isMelympicsActive = true;
    }

    function handleClick(event: Event) {
        if (page === 'overlay') {
            const em = event as MouseEvent;
            if (em.metaKey || em.ctrlKey) return; // do not close on command-clicks
            closeOverlay();
        }
    }

    // for a11y compliance
    function handleKeydown() {
        // do nothing
    }

    function handleGoHome() {
        if (page === 'overlay')
            closeOverlay();
    }

    function closeOverlay() {
        dispatch('close-overlay');
    }
</script>

<TopNav on:goHome={handleGoHome}/>
<div class="overlay-menu">
    <div class="Spacer"></div>
    <button 
        class="overlay-category active-category" 
        on:click={showSandboxes} 
        class:active-category={isSandboxesActive} 
        class:inactive-category={!isSandboxesActive}
        id="sandboxes">Sandboxes
    </button>
    <button 
        class="overlay-category inactive-category" 
        on:click={showMelympics} 
        class:active-category={isMelympicsActive} 
        class:inactive-category={!isMelympicsActive}
        id="melympics">Melympics
    </button>
    <div class="Spacer"></div>
</div>
<div class="container" class:overlay={page === 'overlay'} on:click={handleClick} on:keydown={handleKeydown}>
    {#if isSandboxesActive}
        {#if sandboxesList.length > 0}
            {#each sandboxesList as sbx}
                <SandboxEntry sandbox={sbx}/>
            {/each}
        {/if}
    {/if}
    {#if isMelympicsActive}
        {#if melympicsList.length > 0}
            {#each melympicsList as sbx}
                <SandboxEntry sandbox={sbx}/>
            {/each}
        {/if}
    {/if}
</div>


<style>
    .container {
        display: grid;
        grid-auto-rows: minmax(min-content, 167px);
        grid-gap: 1rem;
        overflow-y: auto;
        height: calc(100vh - 5rem);
        background-color: rgb(201, 201, 201);
        padding-top: 4rem;
        padding-left: 15vw;
        padding-right: 15vw;
        padding-bottom: 5rem;
        -webkit-overflow-scrolling: touch;
    
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    
    .container::-webkit-scrollbar {
        display: none;
    }

    .overlay {
        background: rgb(183, 185, 188, 0.7);
    }

    button {
        background-color: transparent;
        border: none;
    }

    .overlay-menu {
        height: 0px;
        width: 100%;
        background-color: rgb(201, 201, 201);
        display: flex;
        flex-direction: row;
    }
    
    .overlay-category {
        color: white;
        height: 43px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 18px;
        font-family: 'Titillium Web', sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        opacity: 1;
        background-color: transparent;
        border: none;
    }

    .active-category {
        opacity: 1;
        text-decoration: none;
    }

    .inactive-category {
        opacity: 0.6;
        text-decoration: none;
    }

    .Spacer {
        flex-grow: 1;
        pointer-events: none;
    }

    /* Media query for desktop and higher (4-column layout) */
    @media screen and (min-width: 1200px) {
        .container {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    
    /* Media query for tablets (3-column layout) */
    @media screen and (max-width: 1199px) {
        .container {
            grid-template-columns: 1fr 1fr;
        }
    }
    
    /* Media query for tablets (2-column layout) */
    @media screen and (max-width: 799px) {
        .container {
            grid-template-columns: 1fr 1fr;
        }
    }
    
    /* Media query for mobile (1-column layout) */
    @media screen and (max-width: 500px) {
        .container {
            grid-template-columns: 1fr;
            padding-left: 0;
            padding-right: 0;
        }

        .overlay-category {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @media (hover: none), (pointer: coarse) {
    /* Styles for devices with no hover 
     or coarse pointer (touch screens) */
     .container {
            height: calc(100vh - 5rem - 90px);
            padding-bottom: calc(5rem + 90px);
        }
    }
</style>