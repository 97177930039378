<div class="top-right-action">
    <button id="discord" class="icon-text-button">
        <a href="https://discord.gg/jVej4pJQwc" target='_blank'>
            <img id="discord-icon" style="height: 20px; width: auto; margin-top: 5px;" src="/assets/icons/discord.png" alt="Discord" />
        </a>
    </button>
</div>

<style>
    .icon-text-button {
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 15px;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        border: none;
    }
</style>