<script lang="ts">
	export let showModal: boolean; // boolean
    export let onClose: () => void = () => {}; // close event handler
    export let width: string = 'calc(100vw - 60px)';
    export let height: string = 'calc(100vh - 60px)';

	let dialog: HTMLDialogElement; // HTMLDialogElement

	$: if (dialog && showModal) { dialog.showModal() } else if (dialog && !showModal) { dialog.close() };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
	bind:this={dialog}
    on:close={() => {
        showModal = false;
        onClose();
    }}
    on:click|self={() => {
        dialog.close();
    }}
    style="width: {width}; height: {height};"
>
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<div on:click|stopPropagation>
		<slot name="header" />
		<slot />
	</div>
</dialog>

<style>
	dialog {
		border-radius: 0.0em;
		border: none;
		padding: 0;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.0);
	}
	dialog::backdrop {
		background: rgba(0, 0, 0, 0.7);
	}
	dialog > div {
		padding: 0em;
        width: 100%;
        height: 100%;
	}
	dialog[open] {
		animation: zoom 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
	}
	@keyframes zoom {
		from {
			transform: scale(0.95);
		}
		to {
			transform: scale(1);
		}
	}
	dialog[open]::backdrop {
		animation: fade 0.2s ease-out;
	}
	@keyframes fade {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	button {
		display: block;
	}
</style>
