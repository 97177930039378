<script lang="ts">
    import { createEventDispatcher, onMount, onDestroy } from 'svelte';
    export var expiration: number = 10;
    export var cancelCountdown: boolean = false;
    export let leaderboardButton: boolean;
  
    const dispatch = createEventDispatcher();
    let remainingTime: number = expiration;
    var progress = 0;
    var intervalId: ReturnType<typeof setInterval> | undefined;
  
    const countdownInterval = () => {
        if (remainingTime > 0) {
            remainingTime -= 1;
        } else {
            if (!cancelCountdown) clickButton();
        }
    };
  
    const startCountdown = () => {
        progress = 0;
        remainingTime = expiration;
        clearInterval(intervalId);
        intervalId = setInterval(countdownInterval, 1000);
    };
  
    const clickButton = () => {
        clearInterval(intervalId);
        intervalId = undefined;
        console.log( "Next Race Button");
        dispatch('clicked', {});
    };

    const handleClickOutside = (event: MouseEvent) => {
        const button = document.querySelector('.buttonResults');
        if (button && !button.contains(event.target as Node)) {
            cancelCountdown = true;
            clearInterval(intervalId);
            intervalId = undefined;
        }
    };

    const handleScroll = () => {
        cancelCountdown = true;
        clearInterval(intervalId);
        intervalId = undefined;
    };

    onMount(() => {
        intervalId = undefined;
        startCountdown();
        document.addEventListener('click', handleClickOutside);
        document.querySelector('.leaderboardSwitcherContainer')?.addEventListener('scroll', handleScroll);        
    });
  
    onDestroy(() => {
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('scroll', handleScroll);
        cancelCountdown = true;
        clearInterval(intervalId);
    });

</script>

<style>
    button {
        display: block;
        border: none;
        cursor: pointer;
        background: none;
        z-index: 1;

        /** Hide the built-in outline when clicking buttons. */
        outline: none;
    }

    .buttonContainerResults {
        width: auto;
    }

    .buttonResults {
      position: relative;
      overflow: hidden;
      background-color: #e3a437;
      color: #ffffff;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      width: 214px;
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .leaderboardButtonIcon {
        background-image: url('/assets/icons/shuffle_icon.png');
        height: 30px;
        width: 30px;
        background-size: cover;
        background-position: center;
    }
    .leaderboardButtonText {
        padding-left: 5px;
        font-size: 16px;
        font-family: 'Titillium Web', sans-serif;
        color: #fff;
        opacity: 1;
        align-self: center;
    }
    .remainingTime {
        opacity: 80%;
        font-weight: normal;
    }

    @media (max-width: 768px) {
        .buttonContainer {
            width: 100%;
        }
    }

    /* temp for buttons on race results splash */
    @media (max-width: 480px) {
        .buttonResults {
            width: 100%;
        }
    }
</style>

<!-- temp button classes for race results page -->
<div class="buttonContainerResults">
    <button class="buttonResults" on:click="{clickButton}">
        {#if leaderboardButton}
            <span class="leaderboardButtonIcon"></span>
            <span class="leaderboardButtonText">
                Next Match
                {#if intervalId}
                    <span class="remainingTime">in {remainingTime}</span>
                {/if}
            </span>
        {:else}
                <slot />
        {/if}
    </button>
</div>