<script lang="ts">
    import type { Race } from "@/data/prefabs/Race.js";
    import LeaderBoardSwitcher from "@/elements/LeaderBoard/LeaderBoardSwitcher.svelte";
    import AutoClickButton from "@/elements/NavigationElements/AutoClickButton.svelte";
    import { onMount, createEventDispatcher } from "svelte";
    import { truncateName, truncateNameEnd } from "@/helpers/race.js";
    export var race : Race;
    var cancelAutoRace = false;
    export var countdown:boolean = true;

    let boardsReady=false;

    const dispatch = createEventDispatcher();
    function onNextRace() {
        console.log( "Next Race Post Race Overlay");
        dispatch('next-race');
    }
    function onRematch() {
        dispatch('rematch');
    }
    function clickbg() {
        cancelAutoRace = true;
    }
    function eatkey() {
    }
    function onBoardsReady() {
        setTimeout(() => { boardsReady = true; }, 2000);
    }

    onMount(() => {
        // temp ID for showing buttons on race results splash
        const div = document.getElementById('postRaceContainerButtons');
        if( div?.parentElement )
            div.parentElement.style.height = '100%';
    });
</script>

<div id="postRaceContainerButtons" class:hidden={!boardsReady}>
    <div class="background" on:click={clickbg} on:keydown={eatkey}>
        <LeaderBoardSwitcher on:boards-ready={onBoardsReady} leaderboardEventName={race.eventName} race={race} boards={race.getLeaderboards()}></LeaderBoardSwitcher>
        <div class="buttons-container-results">
            <button class="buttonResults" on:click={onRematch}>
                <span class="raceResultsButtonIcon"></span>
                <span class="raceResultsButtonText">Rematch</span>
            </button>
            {#if countdown && boardsReady}
            <AutoClickButton expiration={20} leaderboardButton={true} bind:cancelCountdown={cancelAutoRace} on:clicked={onNextRace}>
                {race.realtimeData && race.realtimeData[0] ? truncateName(race.realtimeData[0].name) : 'Next Match'} vs. {truncateNameEnd(race.nextRacer)}
            </AutoClickButton>
            {/if}
        </div>    
    </div>
</div>

<style>
    button {
        display: block;
        border: none;
        cursor: pointer;
        background: none;
        z-index: 1;

        /** Hide the built-in outline when clicking buttons. */
        outline: none;
    }

    #postRaceContainer, #postRaceContainerButtons {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        flex-direction: column;
        animation: fadeInAnimation ease-out 0.2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    #postRaceContainer.hidden, #postRaceContainerButtons.hidden {
        display: none;
    }

    #postRaceContainerButtons {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background-color: rgba(226, 226, 226, 1.0);
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .buttons-container-results {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100% - 40px);
        gap: 6px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .buttonResults {
        position: relative;
        overflow: hidden;
        background-color: #e3a437;
        color: #f1f1f1;
        font-family: 'Titillium Web', sans-serif;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        width: 214px;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .background {
        width: 75%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: start;
        max-width: 830px;
        min-height: 40%;
        margin-top: 50px;
    }
    .button {
        position: relative;
        overflow: hidden;
        background-color: #e3a437;
        color: #f1f1f1;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        width: 50%;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(75% - 16px);
        padding: 12px 0 30px 0;
        max-width: 810px;
        gap: 12px;
        margin-right: 16px;
        margin-bottom: 20px;
    }
    .raceResultsButtonIcon {
        background-image: url('/assets/icons/rematch_icon.png');
        height: 30px;
        width: 30px;
        background-size: cover;
        background-position: center;
    }
    .raceResultsButtonText {
        padding-left: 5px;
        font-size: 16px;
        color: #fff;
        opacity: 1;
        align-self: center;
    }

    @media (max-width: 768px) {
        .background {
            width: 100%;
            min-width: 320px;
            margin-top: 20px;
        }
        .buttons-container {
            flex-direction: column;
            width: calc(100% - 60px);
            margin: 0 40px 0 20px;
            min-width: 260px;
            padding: 12px 0 20px 0;
        }
        .button {
            width: 100%;
        }
    }

    /* temp for buttons on race results splash */
    @media (max-width: 480px) {
        .buttons-container-results {
            flex-direction: column;
            justify-content: end;
        }
        .buttonResults {
            width: 100%;
        }
    }
</style>
