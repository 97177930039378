<script lang='ts'>
    import { getScores } from "@/helpers/score_reporting.js";
    import SvelteTable from "svelte-table";
    import { type Leaderboard as BoardData } from '@/helpers/score_reporting.js';
    import {type TableColumn} from "svelte-table";
    import { type Race, convertSecondsToStopwatch } from "@/data/prefabs/Race.js";
    import { BoardType } from "@/types/enums.js";
    import MelImage from "@/components/charts/MelImage.svelte";
    import { createEventDispatcher, onMount } from "svelte";
    export let board_data: BoardData;
    export let boardType: BoardType;
    export let race: Race|null;
    export let show: boolean = false;

    type DataEntry = {
        score: number,
        recorded_at: string
        aesthetic: string;
        policy: string;
        policy_suffix: string;
        agent_name: string;
    }

    const maxScores = race?.maxScores ?? 100;
    var rows : DataEntry[] =[];
    var columns : TableColumn<DataEntry>[] = [
        {
            key: "place",
            title: "Place",
            sortable: false,
        },{
            key: "mel_image",
            title: "",
            sortable: false,
        },{
            key: "agent_name",
            title: "Mel",
            sortable: false,
        },{
            key: "score",
            title: "Time",
            sortable: false,
        }
    ];
    
    const dispatch = createEventDispatcher();
    function signalReady() {
        dispatch('ready');
    }

    function findRacerIndex() {
        racerPolicyIndex = -1;
        if (racerPolicy) {
            console.log("Looking for", racerPolicy, "in the results list...");
            for (let i = 0; i < rows.length; i++) {
            if (rows[i].policy === racerPolicy) {
                racerPolicyIndex = i;
                break;
            }
            }
            console.log("Index:", racerPolicyIndex);
        } else {
            console.log("No racer info?!?");
        }
    }


    let racerPolicy = "";
    let racerCurrentTime = -1;
    let racerCurrentChaos = -1;

    onMount( ()=>{
        console.log("board_data changed to:", board_data);

        console.log(`Start loading up to ${maxScores} scores from the cache/backend...`);
        board_data && getScores(board_data, maxScores, false).then( (p)=>{
            const s = p();
            console.log("Got scores so updating rows, etc...");
            rows = [...s];
            findRacerIndex();
            signalReady();
        } );

        console.log("race.realtimeData:", race?.realtimeData);
        if (race && race.realtimeData && race.realtimeData.length > 0) {
          const racer = race.realtimeData[0];
          console.log("Racer:", racer.name, "policy:", racer.policy,
                      "current time:", racer.finishTime, "current chaos:", racer.chaos);
          racerPolicy = racer.policy;
          racerCurrentTime = racer.finishTime??-1;
          racerCurrentChaos = racer.chaos;
        } else {
            racerPolicy = "";
        }
    })

    let racerPolicyIndex = -1;
    let initialScrollToViewShown: boolean = false;
    $: {
        if(show && boardType === BoardType.Time && !initialScrollToViewShown) {
            if (racerPolicyIndex >= 0 && racerPolicyIndex < rows.length) {
                setTimeout(() => {
                    const racerRowNumber = racerPolicyIndex + 1; 
                    console.log('Scrolling to racer row', racerRowNumber);
                    const racerRow = document.getElementsByClassName('row' + racerRowNumber)[0];
                    racerRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    initialScrollToViewShown = true;
                }, 2000 + 1000); // 2000 is delay for showing leaderboard after race results stop
            }
        }
    }

    function getOpponentUrl(agentName: string, hashSuffix: string) {
        const selectedMel = agentName + "." + hashSuffix;
        const srch = new URLSearchParams(window.location.search);
        let melsParamValues = selectedMel;
        let urlPath = '/training';
        const mels = srch.get('mels')?.split('~');
        if (mels) { // if multi-mel sandbox, set selected Mel as opponent
            melsParamValues = [mels[0], selectedMel].join('~');
            urlPath = window.location.pathname;
        }
        srch.set('mels', melsParamValues);
        return (urlPath + '?' + srch.toString());
    }
</script>

<style>
    .main {
        width: 100%;
        font-size: 18px;
    }
    .main.hidden {
        display: none;
    }
    td {
        text-align: left;
        padding: 0 0;
        height: 30px;
    }
    td.padding {
        padding: 10px;
    }
    td.col1 {
        width: 10%;
        min-width: 44px;
    }
    td.col2 {
        width: 8%;
        min-width: 64px;
        transform: scale(0.75);
    }
    td.col3 {
        width: 70%;
        font-weight: 400;
        padding-left: 20px;
    }
    td.col4 {
        width: 12%;
        min-width: 90px;
    }
    td.editMel {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
    }
    td.rightAlign {
        text-align: right;
    }
    tr {
        background-color: #f9f9f9;
        box-shadow: 1px 1px 2px #d0d0d0;
    }
    tr.highlight {
        background-color: rgba(242, 193, 233, 1.0);
    }
    .currentScore {
        font-style: italic;
        color: rgb(155, 0, 127);
    }

    .spacer {
        background-color: transparent;
        height: 6px;
        padding: 0;
        box-shadow: none;
    }
    .horizontalRule {
        height: 1px;
        width: 100%;
        background-color: #b9b9b9;
        margin: 0 0 12px 0;
    }

    tr.row1 {
        background-color: #eece81;
    }

    tr.row2 {
        background-color: #c8c8c8;
    }

    tr.row3 {
        background-color: #e5ad8a;
    }
    
    div.medal1, div.medal2, div.medal3, button.builder {
        width: 32px;
        height: 32px;
        margin-left: -6px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        font-weight: 400;
        padding-top: 3px;
        font-size: 13px;
        color: rgba(256, 256, 256, 0.0);
    }
    div.medal1 {
        background-image: url('/assets/icons/medal_gold_icon.png');
    }
    div.medal2 {
        background-image: url('/assets/icons/medal_silver_icon.png');
    }
    div.medal3 {
        background-image: url('/assets/icons/medal_bronze_icon.png');
    }
    div.noMedal {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
    }
    div.nameBuilderIcon {
        display: flex;
        justify-content: start;
        align-items: center;
    }
    button.builder {
        width: 28px;
        height: 28px;
        background-image: url('/assets/icons/builder_gray.png');
        border: none;
        cursor: pointer;
        margin-left: 15px;
    }

    .shorthash {
        opacity: 0.4;
        font-weight: 400;
    }

    .leaderboardLink {
        color: inherit;
        text-decoration: none;
        outline: none;
    }

    .mobileBreak {
        display: none;
    }

    @media (max-width: 768px) {
        .main {
            font-size: 14px;
        }
        td.padding {
            padding: 5px;
        }
        td.col1 {
            min-width: 44px;
        }
        td.col2 {
            min-width: 50px;
        }
        td.col4 {
            min-width: 50px;
        }
        button.builder {
            margin-left: 7px;
        }
        div.medal1, div.medal2, div.medal3, button.builder {
            width: 28px;
            height: 28px;
        }
    }

@media (max-width: 400px) {
    .mobileBreak {
        display: block;
    }
}
</style>

<div class="main" class:hidden={!show}>
    <SvelteTable rows={rows} columns={columns} classNameTable="borderCollapse" classNameThead="hiddenThead">
        <svelte:fragment slot="row" let:row let:n>
        <tr class="row{n + 1} {racerPolicyIndex === n ? 'highlight' : ''}">
            <td class="padding"></td>
            <td class="col1">
            {#if row.aesthetic}
                <div class="medal{n + 1}">#{n + 1}</div>
            {/if}
            </td>
            <td class='col2'>
            {#if row.aesthetic}
                <a class="leaderboardLink" href="/training?mels={row.agent_name}.{row.policy_suffix}"><MelImage circleThumb={false} imageName={row.aesthetic} imagetype="sports" /></a>
            {/if}
            </td>
            {#if row.agent_name}
                <td class="col3">
                    <a class="leaderboardLink" href="{getOpponentUrl(row.agent_name, row.policy_suffix)}">{row.agent_name}<br class="mobileBreak"/><span class="shorthash">#{row.policy_suffix}</span></a>
                </td>
            {/if}
            {#if row.score !== undefined && boardType === BoardType.Time}
                <td class="col4 rightAlign">{convertSecondsToStopwatch(row.score)}
                {#if n == racerPolicyIndex && row.score < racerCurrentTime}
                    <br/><span class="currentScore">{convertSecondsToStopwatch(racerCurrentTime)}</span>
                {/if}
                </td>
            {:else if row.score !== undefined && boardType === BoardType.Chaos}
                <td class="col4 rightAlign">{row.score.toFixed(0)}
                {#if n == racerPolicyIndex && row.score > racerCurrentChaos}
                    <br/><span class="currentScore">{racerCurrentChaos.toFixed(0)}</span>
                {/if}
                </td>
            {:else}
                <td class="col4 rightAlign">{'N/A'}</td>
            {/if}
            <td class="padding"></td>
        </tr>
        <tr class="spacer">
            <td colspan="7" class="spacer"></td>
        </tr>
        </svelte:fragment>
    </SvelteTable>
</div>
