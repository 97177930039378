import SandboxesOverlay from '@/elements/Sandboxes/SandboxesOverlay.svelte';
import { MelSveltePage } from '@/elements/MelSveltePage.js';

export class MelSandboxOverlay extends MelSveltePage<SandboxesOverlay> {
    constructor() {
        super(SandboxesOverlay);
    }

    override getCSSFiles(): string[] | undefined {
        return ['./css/sandbox-menu.css'];
    }

    protected override setupComponent(): void {
        this.comp?.$on('close-overlay', () => {
            this.close();
        });
    }
}

if (!customElements.get('mel-sandbox-overlay')) {
    customElements.define('mel-sandbox-overlay', MelSandboxOverlay);
}
