<script lang="ts">
    import TopNav from '@/elements/Navigation/TopNav.svelte';
    import Help from '@/elements/Layout/Help.svelte';
    import Footer from '@/elements/Layout/Footer.svelte';
    import Modal from '../Modal/Modal.svelte';

    let showModal = false;
    const topLeftImage = '/assets/icons/tile_how_to_train_your_mel.jpg';
    const topRightImage = 'assets/icons/tile_melympics.jpg';
    const bottomLeftImage = '/assets/icons/tile_browse_mels.jpg';
    const bottomCenterImage = '/assets/icons/tile_build_a_mel.jpg';
    const bottomRightImage = '/assets/icons/tile_play_in_sandboxes.jpg';

    function goBanner() {
        showModal = true;
    }

    function goBuilder() {
        globalThis.app.history.push('/builder');
    }

    function goShowcase() {
        globalThis.app.history.push('/mels');
    }

    function goTrainHumanoid() {
        globalThis.app.history.push('/training?mel=Humanoid.nn4hd&builder=1&forcecopy=1');
    }

    function goSandboxes() {
        globalThis.app.history.push('/sandboxes');
    }
    
    function goMelympics() {
        globalThis.app.history.push('/melympics');
    }

    function goHelp() {
        globalThis.app.history.push('/help');
    }

    let youtubeIframe: HTMLIFrameElement;
    function handleModalClose() {
        if (youtubeIframe && youtubeIframe.contentWindow) {
            youtubeIframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        }
    }

    function closeModal() {
        showModal = false;
    }

    // for a11y compliance
    function handleKeydown() {
        // do nothing
    }

    function lazyLoad(node: any) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const imageUrl = node.dataset.bg;

                    // Create a new image element
                    const img = new Image();

                    // When the image is loaded
                    img.onload = function () {
                        // Apply the background image
                        node.style.backgroundImage = `url(${imageUrl})`;

                        // Add the 'loaded' class to trigger the fade-in animation
                        node.classList.add('reveal');
                    };

                    // Set the src to start loading the image
                    img.src = imageUrl;

                    observer.unobserve(node);
                }
            });
        });

        observer.observe(node);

        return {
            destroy() {
                observer.unobserve(node);
            },
        };
    }
</script>

<TopNav />
<Help />
<Modal bind:showModal onClose={handleModalClose}>
    <div id="video-embed">
        <iframe
            bind:this={youtubeIframe}
            src="https://www.youtube.com/embed/m4zY-4T0fDY?si=AyjYbEjnmCdC5BtS&start=29&enablejsapi=1"
            title="YouTube Video Player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
        />
        <!--
        <video width="100%" height="100%" controls>
            <source src="video.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        -->
        <button class="close-modal-button sans-serif" on:click={closeModal} on:keydown={handleKeydown}>
            <img class="standard-ui-icon" src="/assets/icons/close.png" alt=""/>
        </button>
    </div>
</Modal>
<div class="containerCenter">
    <div class="container">
        <div class="dashboard sans-serif">
            <div class="tile large" on:click={goBanner} on:keydown={handleKeydown} data-bg={topLeftImage} use:lazyLoad>
                HOW TO TRAIN YOUR MEL
            </div>
            <div class="tile" on:click={goMelympics} on:keydown={handleKeydown} data-bg={topRightImage} use:lazyLoad>
                COMPETE IN MELYMPICS
            </div>
            <div class="tile" on:click={goShowcase} on:keydown={handleKeydown} data-bg={bottomLeftImage} use:lazyLoad>
                BROWSE MELS
            </div>
            <div class="tile" on:click={goBuilder} on:keydown={handleKeydown} data-bg={bottomCenterImage} use:lazyLoad>
                TRAIN YOUR OWN
            </div>
            <div class="tile" on:click={goSandboxes} on:keydown={handleKeydown} data-bg={bottomRightImage} use:lazyLoad>
                TEST IN SANDBOXES
            </div>
        </div>
    </div>
</div>
<Footer />

<style>
    .sans-serif {
        font-family: 'Titillium Web', sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        font-variation-settings: 'wdth' 100;
    }

    .containerCenter {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgb(201, 201, 201);
        touch-action: auto;
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        -webkit-overflow-scrolling: touch;
    }

    .containerCenter::-webkit-scrollbar {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        max-height: calc(100% - 124px);
        padding: 1rem;
    }

    .dashboard {
        display: grid;
        gap: 1rem;
    }

    .tile {
        background-color: #bbb;
        position: relative;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: 400;
        text-shadow: 1px 1px 3px rgb(0, 0, 0, 0.8);
        box-shadow: 1px 1px 4px #aaaaaa;
        background-size: cover;
        background-position: center;
    }

    .tile.reveal {
        visibility: hidden;
        opacity: 0;
        transition:
            visibility 0s,
            opacity 0.5s ease;
    }

    .tile::before {
        content: '';
        display: block;
        padding-top: 80%;
    }

    .tile.large {
        grid-column: span 2;
    }

    .tile.large::before {
        padding-top: calc(40% - 0.5rem);
    }

    #video-embed {
        margin-top: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #video-embed iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .video-modal-button {
        background-color: #e3a437;
        color: #ffffff;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        max-width: 360px;
        height: 48px;
        margin: 20px 20px 20px 20px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 20px;
    }

    .video-modal-button img {
        align-self: center;
    }

    .close-modal-button {
        background-color: #000000;
        width: 32px;
        height: 32px;
        border-radius: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .BuilderIcon {
        height: 30px;
        width: 30px;
        background-size: cover;
        background-position: center;
        background-image: url('assets/icons/builder.png');
    }

    @media (min-width: 1024px) {
        .containerCenter {
            align-items: center;
        }

        .dashboard {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (min-width: 600px) and (max-width: 1023px) {
        .containerCenter {
            padding-top: 46px;
        }

        .dashboard {
            grid-template-columns: repeat(2, 1fr);
        }

        .dashboard > :last-child,
        .dashboard > :nth-last-child(2) {
            margin-bottom: 101px; /* 85px footer + 1rem */
        }
    }

    @media (max-width: 599px) {
        .containerCenter {
            padding-top: 46px;
        }

        .dashboard {
            grid-template-columns: 1fr;
        }

        .dashboard > :last-child {
            margin-bottom: 101px; /* 85px footer + 1rem */
        }

        .tile.large {
            grid-column: span 1;
            height: auto;
        }

        .tile.large::before {
            padding-top: 80%;
        }
    }
</style>
