import { MelSveltePage } from '@/elements/MelSveltePage.js';
import Help from '@/elements/Help/Help.svelte';

export class MelHelp extends MelSveltePage<Help> {
    constructor() {
        super(Help);
    }

    override getCSSFiles(): string[] | undefined {
        return ['./css/help.css'];
    }
}

if (!customElements.get('mel-help')) {
    customElements.define('mel-help', MelHelp);
}
