import '@ungap/custom-elements';
import 'element-internals-polyfill';
import env from '@/helpers/env';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';

import App from '@/components/App';
import input from '@/helpers/input';
import '@/elements/MelUi';
import '@/components/scenes/editor/BuilderUI';
import '@/elements/MelDashboard';
import '@/elements/MelMelympics';
import '@/elements/MelShowcase';
import '@/elements/MelShowcaseOverlay';
import '@/elements/MelSandboxes';
import '@/elements/MelSandboxesOverlay';
import '@/elements/MelHelp';
import '@/elements/RaceElements/RaceOverlay';
import '@/components/scenes/editor/BuilderInfoPanel';
import { clientLog } from '@/helpers/client_log.js';
import posthog from 'posthog-js';
import { getJWTClaim } from '@/helpers/jwt.js';
import { advancedBuilderMode } from '@/helpers/feature_flags.js';

const canvas = document.getElementById('canvas') as HTMLCanvasElement;
const app = new App(canvas);

async function main() {
    document.body.classList.remove('preload');
    const auth = app.auth;
    if (!IS_DEV) {
        posthog.init('phc_RkcLH81ILlF2gr3PkgPwexRAiKR8JuwX9RS604OHNp4', {
            ui_host: 'https://us.i.posthog.com',
            api_host: '/ingest',
            person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        });
    }

    try {
        await auth.doLogin();
        try {
            await auth.FetchMelJWT();
        } catch (e: any) {
            const err: Error = e as Error;

            console.error(e);
            const data = {
                error: e,
                emsg: err.message,
                ename: err.name,
                email: GOOGLE_OAUTH ? auth.gloginresult?.user.email ?? 'none' : 'not using oauth',
                host: window.location.host,
            };
            await clientLog('MelAuthFail', data);
            window.location.assign('https://pre-alpha.mels.ai/?utm_source=auth_redirect');
            return;
        }
    } catch (error: any) {
        console.error(`Auth Error ${error}`);
        await clientLog('FirebaseAuthFail', { error, host: window.location.host });
        return;
    }

    // Identify to posthog
    const jwt = await auth.getMelToken();
    const utulid = await auth.getUserTulid();
    const name = getJWTClaim(jwt, 'name');
    const email = getJWTClaim(jwt, 'email');
    const username = getJWTClaim(jwt, 'username');
    const display_username = getJWTClaim(jwt, 'display_username');
    const user_category = getJWTClaim(jwt, 'user_category');
    const added_at = getJWTClaim(jwt, 'added_at');
    const first_auth_at = getJWTClaim(jwt, 'first_auth_at');
    posthog.identify(utulid, undefined, {
        name,
        email,
        username,
        display_username,
        user_category,
        added_at,
        first_auth_at,
    });
    posthog.onFeatureFlags(() => {
        advancedBuilderMode();
    });

    if (auth.approved) {
        await app.processURL();
    } else {
        console.error('login not approved');
        await clientLog('LoginNotApproved', {
            email: GOOGLE_OAUTH ? auth.gloginresult?.user.email ?? 'none' : 'not using oauth',
            host: window.location.host,
        });
        window.location.assign('https://pre-alpha.mels.ai/?utm_source=auth_redirect');
    }
}

const windowLoaded = document.readyState === 'complete';

if (windowLoaded) main();
else window.addEventListener('load', main);

//
// Development
//
if (env.COMMIT_HASH === '__local__') {
    var lastChange: number | undefined = undefined;
    setInterval(async () => {
        const r = await fetch(`${window.location.protocol}//${window.location.host}/reload`);
        const j = await r.json();
        if (lastChange) {
            if (lastChange < j.time) window.location.reload();
            lastChange = j.time;
        } else lastChange = j.time;
    }, 3000);
}

//
// Debug
//

if (typeof window === 'object') {
    // @ts-expect-error
    window.env = env;
    // @ts-ignore
    window.input = input;
}
