import { MelSveltePage } from '@/elements/MelSveltePage.js';
import Melympics from '@/elements/Melympics/Melympics.svelte';

export class MelMelympics extends MelSveltePage<Melympics> {
    constructor() {
        super(Melympics);
    }

    override getCSSFiles(): string[] | undefined {
        return ['./css/melympics.css'];
    }
}

if (!customElements.get('mel-melympics')) {
    customElements.define('mel-melympics', MelMelympics);
}
