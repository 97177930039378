<script lang="ts">
    import Sandboxes from '@/elements/Sandboxes/Sandboxes.svelte';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    function closeOverlay() {
        dispatch('close-overlay');
    }
</script>

<div class="top-right-action">
    <button id="back" class="icon-text-button" on:click={closeOverlay}>
        <img class="standard-ui-icon" src="/assets/icons/close.png" alt=""/>
    </button>
</div>
<Sandboxes page="overlay" on:close-overlay={closeOverlay}/>

<style>
    button {
        background-color: transparent;
        border: none;
    }
</style>