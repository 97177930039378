<script lang="ts">
    import { createEventDispatcher, onMount } from 'svelte';
    import LeaderBoard from '@/elements/LeaderBoard/LeaderBoard.svelte';
    import { type Leaderboard as BoardData } from '@/helpers/score_reporting.js';
    import type { Race } from '@/data/prefabs/Race.js';
    import { BoardType } from '@/types/enums.js';
    export let boards : BoardData[];
    export let race: Race | null;
    export let leaderboardEventName: string = '';

    let timeReady = false;
    let chaosReady = false;
    let timeSelected = false;
    let chaosSelected = false;

    function showTime() {
        timeSelected = true;
        chaosSelected = false;
    }

    function showChaos() {
        timeSelected = false;
        chaosSelected = true;
    }

    const dispatch = createEventDispatcher();
    function buildOnReady(type : BoardType) {
        return ()=>{
            switch( type ) {
                case BoardType.Time:
                    timeReady = true;
                    break;
                case BoardType.Chaos:
                    chaosReady = true;
                    break;
            }
            if( timeReady && chaosReady ) {
                timeSelected = true;
                dispatch('boards-ready')
            }
        }
    }
</script>

<div class="bar">
    <div class="eventContainer">
        <div class="barLogo"></div>
        <div class="eventTitle">{leaderboardEventName}</div>
    </div>
    <div class="barTitle">Leaderboard</div>
    {#if timeSelected || chaosSelected}
    <div class="toggleMenu">
        <button class="toggleButton selected" class:selected={timeSelected} on:click={showTime}><img class="sortIcon" src="/assets/icons/timer.png" alt=""></button>
        <button class="toggleButton" class:selected={chaosSelected} on:click={showChaos}><img class="sortIcon" src="/assets/icons/chaos.png" alt=""></button>
    </div>
    {/if}
</div>
<div class="podium">
</div>
<div class="leaderboardSwitcherContainer">
    <LeaderBoard on:ready={buildOnReady(BoardType.Time)} show={timeSelected} race={race} boardType={BoardType.Time} board_data={boards[0]}/>
    <LeaderBoard on:ready={buildOnReady(BoardType.Chaos)} show={chaosSelected} race={race} boardType={BoardType.Chaos} board_data={boards[1]}/>
</div>

<style>
    .leaderboardSwitcherContainer {
        font-family: 'Titillium Web', sans-serif;
        overflow-y: auto;
        overflow-x: hidden;
        width: calc(100% - 16px);
        height: 100%;
    }
    .bar {
        font-family: 'Titillium Web', sans-serif;
        color: #f1f1f1;
        height: 40px;
        min-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
        padding-right: 20px;
        margin-left: -3px;
        margin-right: 1px;
        position: relative;
        background: linear-gradient(105deg, transparent 14px, #222 14px, #222 calc(100% - 14px), transparent calc(100% - 14px));
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 100;
    }

    .mainContainer {
        background-image: url('/assets/icons/melympics_logo_flat.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
        background-size: 44px;
        /* height: 20px;
        width: 44px; */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .subContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .barTitle {
        font-size: 18px;
        text-transform: none;
        font-weight: 400;
    }

    .barLogo {
        background-image: url('/assets/icons/melympics_logo_flat.png');
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
        height: 16px;
        width: 32px;
    }

    .eventContainer {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 10px;
        width: 180px;
    }

    .eventTitle {
        font-size: 16px;
        text-align: left;
        font-weight: 300;
        opacity: 0.9;
    }
    .toggleMenu {
      position: relative;
      display: flex;
      justify-content: end;
      width: 180px;
    }
    .toggleButton {
        color: #fff;
        opacity: 0.5;
        border: none;
        background: none;
        padding: 6px;
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        text-transform: uppercase;
        font-family: 'Titillium Web', sans-serif;
    }

    .podium {
        width: calc(100% - 16px);
        margin-top: 0px;
        margin-bottom: 6px;
        min-height: 0px;
        background-color: skyblue;        
    }

    .selected {
        opacity: 1.0;
    }

    .sortIcon {
        height: 24px;
        width: auto;
        opacity: 1.0;
        display: flex;
    }

    @media (max-width: 768px) {
        .leaderboardSwitcherContainer {
            width: calc(100% - 40px);
            margin-left: auto;
            margin-right: auto;
        }
        .podium {
            width: calc(100% - 40px);
            margin-left: auto;
            margin-right: auto;
        }
        .bar {
            background: linear-gradient(90deg, transparent 14px, #222 14px, #222 calc(100% - 14px), transparent calc(100% - 14px));
            margin-left: 5px;
            margin-right: 5px;
        }
        .barTitle {
            display: none;
        }
        .eventTitle {
            width: auto;
        }
        .toggleMenu {
            width: 50%;
        }
    }
</style>
