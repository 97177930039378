<script lang="ts">
    import TopNav from '@/elements/Navigation/TopNav.svelte';
    import { onMount } from 'svelte';
    import Modal from '../Modal/Modal.svelte';
    import LeaderBoardSwitcher from "@/elements/LeaderBoard/LeaderBoardSwitcher.svelte";
    import type { Leaderboard } from '@/helpers/score_reporting.js';

    let showTwitchModal = false;
    let showLeaderboardsModal = false;
    let isDashLeaderboardActive = true;
    let isDragLeaderboardActive = false;
    let isWrestlingLeaderboardActive = false;

    const bannerImage = 'assets/images/leaderboard.jpg';
    const bottomLeftImage = '/assets/playgrounds/100m.png';
    const bottomCenterImage = '/assets/playgrounds/dragstrip.jpg';
    const bottomRightImage = '/assets/playgrounds/wrestling.jpg';

    function goTwitch() {
        showTwitchModal = true;
    }

    function goBanner() {
        showLeaderboardsModal = true;
    }

    function go100m() {
        globalThis.app.history.push('/play?p=S-D880D6B43988&mels=Handy.zcks6~Lanky.9n4n5');
    }

    function goDrag() {
        globalThis.app.history.push('/play?p=S-6225B281117D&mels=BurnoutBilly.48pq1~TenFourGoodBuddy.mar32');
    }

    function goWrestling() {
        globalThis.app.history.push('/play?p=S-2641C0F67D32&mels=HighMass-V1-2-1.8vwrh~RedDragon.8hmme');
    }

    // for a11y compliance
    function handleKeydown() {
        // do nothing
    }

    function lazyLoad(node: any) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const imageUrl = node.dataset.bg;

                    // Create a new image element
                    const img = new Image();

                    // When the image is loaded
                    img.onload = function () {
                        // Apply the background image
                        node.style.backgroundImage = `url(${imageUrl})`;

                        // Add the 'loaded' class to trigger the fade-in animation
                        node.classList.add('reveal');
                    };

                    // Set the src to start loading the image
                    img.src = imageUrl;

                    observer.unobserve(node);
                }
            });
        });

        observer.observe(node);

        return {
            destroy() {
                observer.unobserve(node);
            },
        };
    }

    function closeModal() {
        showTwitchModal = false;
    }

    onMount(() => {
        const script = document.createElement('script');
        script.src = 'https://player.twitch.tv/js/embed/v1.js';
        script.onload = () => {
            new Twitch.Player('twitch-embed', {
                width: '100%',
                height: '100%',
                channel: 'mels_ai'
            });
        };
        document.body.appendChild(script);

        // for usability, close modal if control bar outside buttons is tapped
        document.getElementById('leaderboardModalControls')?.addEventListener('click', function(event) {
            if (event.target === this) showLeaderboardsModal = false;
        });
    });

    // required helpers to create leaderboards without sandboxes/races
    let boardsReady = false;
    function onBoardsReady() {
        boardsReady = true;
    }

    enum SandboxIDs {
        Dash = '915c8c98-596e-463e-bdcb-fe6145ef1550-saved',
        Drag = '915c8c98-596e-463e-bdcb-fe6145ef1550-drag',
        Wrestling = 'a3ad8249-d03b-40c0-b380-f16695f029fb-wrestling',
    };

    function getLeaderboards(sandboxId: SandboxIDs): Leaderboard[] {
        const ret : Leaderboard[] = [
            {
                name: 'Time',
                primarySort: 'ascending',
                secondarySort: 'descending',
                scoreName: sandboxId + '_time',
            },
            {
                name: 'Chaos',
                primarySort: 'descending',
                secondarySort: 'descending',
                scoreName: sandboxId + '_chaos',
            }
        ];
        return ret;
    }
    
    let currentBoard = 1;
    function previousBoard() {
        currentBoard = ((currentBoard - 2 + 3) % 3) + 1;
        updateBoard();
    }

    function nextBoard() {
        currentBoard = (currentBoard % 3) + 1;
        updateBoard();
    }

    function updateBoard() {
        isDashLeaderboardActive = isDragLeaderboardActive = isWrestlingLeaderboardActive = false;
        switch (currentBoard) {
            case 1: 
                isDashLeaderboardActive = true; 
                break;
            case 2: 
                isDragLeaderboardActive = true;
                break;
            case 3: 
                isWrestlingLeaderboardActive = true;
                break;
        }
        document.getElementById('leaderboardContainer').scrollTop = 0
    }
</script>

<TopNav />
<div class="top-right-action" on:click={goTwitch} on:keydown={handleKeydown} data-bg={bannerImage} use:lazyLoad>
    <button id="twitch" class="icon-text-button">
        <img id="twitch-icon" src="/assets/icons/twitch.svg" alt="Twitch" />
    </button>
</div>
<div class="containerCenter">
    <div class="header">
        <div class="title">MELYMPICS</div>
        <div><img class="logo" src="assets/animation/rive/Melympics_logo.png" alt="[][][][][]"></div>
    </div>    
    <div class="container">
        <div class="dashboard sans-serif">
            <div class="tile large" on:click={goBanner} on:keydown={handleKeydown} data-bg={bannerImage} use:lazyLoad>
                VIEW LEADERBOARDS
            </div>
            <div class="tile" on:click={go100m} on:keydown={handleKeydown} data-bg={bottomLeftImage} use:lazyLoad>
                100M DASH
            </div>
            <div class="tile" on:click={goDrag} on:keydown={handleKeydown} data-bg={bottomCenterImage} use:lazyLoad>
                DRAG STRIP
            </div>
            <div class="tile" on:click={goWrestling} on:keydown={handleKeydown} data-bg={bottomRightImage} use:lazyLoad>
                WRESTLING
            </div>
        </div>
    </div>
</div>

<Modal bind:showModal={showTwitchModal}>
    <div id="twitch-embed"></div>
    <button class="close-modal-button sans-serif" on:click={closeModal} on:keydown={handleKeydown}>
        <img class="standard-ui-icon" src="/assets/icons/close.png" alt=""/>
    </button>
</Modal>
<Modal bind:showModal={showLeaderboardsModal} width={'min(calc(100vw - 20px), 768px)'}>
    <div id="leaderboardModalControls">
        <button class="leaderboard-button" on:click={previousBoard}><img src="/assets/icons/chevron-left.png" alt="<"></button>
        <button class="leaderboard-button" on:click={nextBoard}><img src="/assets/icons/chevron-right.png" alt=">"></button>
    </div>
    <div id="leaderboardContainer" class:hidden={!boardsReady}>
        <div class:hidden={!isDashLeaderboardActive}>
            <LeaderBoardSwitcher on:boards-ready={onBoardsReady} leaderboardEventName="100m Dash" race={null} boards={getLeaderboards(SandboxIDs.Dash)}></LeaderBoardSwitcher>
        </div>
        <div class:hidden={!isDragLeaderboardActive}>
            <LeaderBoardSwitcher on:boards-ready={onBoardsReady} leaderboardEventName="Drag Strip" race={null} boards={getLeaderboards(SandboxIDs.Drag)}></LeaderBoardSwitcher>
        </div>
        <div class:hidden={!isWrestlingLeaderboardActive}>
            <LeaderBoardSwitcher on:boards-ready={onBoardsReady} leaderboardEventName="Wrestling" race={null} boards={getLeaderboards(SandboxIDs.Wrestling)}></LeaderBoardSwitcher>
        </div>
    </div>
</Modal>

<style>
    .icon-text-button {
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 15px;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        border: none;
    }

    #twitch-icon {
        height: auto;
        width: 30px;
        display: block;
    }

    #twitch-embed {
        margin-top: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .close-modal-button {
        background-color: #000000;
        width: 32px;
        height: 32px;
        border-radius: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .header {
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        justify-content: flex-end;
    }
    
    .title {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -10px;
        padding-bottom: 10px;
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-weight: 400;
        color: rgb(90, 90, 90);
        opacity: 1;
        text-shadow: 0px 1px 2px rgb(0, 0, 0, 0.2);
        letter-spacing: 2px;
    }
    .logo {
        height: 40px;
    }
    .containerCenter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgb(201, 201, 201);
        touch-action: auto;
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        -webkit-overflow-scrolling: touch;
        background-image: url('/assets/images/melympics-background.jpg');
        background-size: cover;
        background-position: center;
    }

    .containerCenter::-webkit-scrollbar {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        max-height: calc(100% - 124px);
        padding-top: 1rem;
    }
    .sans-serif {
        font-family: 'Titillium Web', sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        font-variation-settings: 'wdth' 100;
    }
    .dashboard {
        display: grid;
        gap: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

    .tile {
        background-color: #bbb;
        position: relative;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: 400;
        text-shadow: 1px 1px 3px rgb(0, 0, 0, 0.8);
        box-shadow: 1px 1px 4px #aaaaaa;
        background-size: cover;
        background-position: center;
        aspect-ratio: 1 / 1;
    }

    .tile.reveal {
        visibility: hidden;
        opacity: 0;
        transition:
            visibility 0s,
            opacity 0.5s ease;
    }

    .tile::before {
        content: '';
        display: block;
        padding-top: 80%;
    }

    .tile.large {
        grid-column: span 3;
        max-height: 320px;
        aspect-ratio: auto;
        background-position: top;
    }

    .tile.large::before {
        padding-top: calc(40% - 0.5rem);
    }

    #leaderboardModalControls {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    #leaderboardContainer {
        height: 100%;
        overflow-y: auto;
        display: flex; 
        flex-direction: column;
    }

    .hidden {
        display: none;
    }

    @media (min-width: 600px) {
        .containerCenter {
            align-items: center;
        }

        .dashboard {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 599px) {
        .containerCenter {
            padding-top: 46px;
        }

        .dashboard {
            grid-template-columns: 1fr;
            padding-left: 0rem;
            padding-right: 0rem;
        }

        .dashboard > :last-child {
            margin-bottom: 101px; /* 85px footer + 1rem */
        }

        .tile.large {
            grid-column: span 1;
            aspect-ratio: 1 / 1;
            background-position-x: -90px;
            background-position-y: -10px;
        }

        .tile.large::before {
            padding-top: 80%;
        }
    }
</style>