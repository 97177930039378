<script lang="ts">
    import type { CarouselEntry } from "@/components/scenes/editor/BuilderUI.js";

    export var carousel_config : CarouselEntry[] = [];

    function clickEntry(e : CarouselEntry) {
        e.link();
        carousel_config = carousel_config;
    }

    function entryEnabled(e : CarouselEntry) {
        if( !e.enabled ) return true;
        return e.enabled();
    }
    function eatKey(){}
</script>

<div class="CarouselRow">
    <div class="Spacer"></div>
    {#each carousel_config as e}
        <div class="CarouselButton" class:disabled={!entryEnabled(e)}>
            <div class="CarouselImage" style="background-image:url('{e.bg_url}')" on:click={()=>clickEntry(e)} on:keypress={eatKey} class:disabled={!entryEnabled(e)}>
            </div>
        </div>
    {/each}
    <div class="Spacer"></div>
</div>

<style>
.CarouselRow {
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.CarouselButton {
    height: 96px;
    width: 96px;
    margin: 4px;
    padding: 4px;
    background-color: #00000020;
    cursor: pointer;
    border-radius: 4px;
}

.CarouselButton:not(.disabled):hover {
    background-color: #ffffff11;
}
.CarouselButton:not(.disabled):active {
    background-color: #ffffff22;
}

.CarouselImage {
    width: 88px;
    height: 100%;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.CarouselImage.disabled {
    filter: grayscale(100%);
}

</style>