<script lang="ts">
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    import { Rive, Layout, Fit, Alignment, EventType, type Event as RiveEvent, type RiveEventPayload, RiveEventType } from '@rive-app/canvas';
    import { getRiveFile, getRiveImage, loadImageBinary, type RiveAssets } from '@/helpers/assets.js';
    import { getAestheticImageLink } from '@/helpers/mel_cloud_storage';

    let canvas : HTMLCanvasElement;
    let riveInstance : Rive | undefined = undefined;
    export let showContent : boolean = false;
    export let riveData: RiveAssets | undefined = undefined;
    export let melympicsEventName: string = '';
    export let melNameLeft = '';
    export let melNameRight = '';
    export let melAestheticRight = '';
    export let melAestheticLeft = '';
    let riveTime = 0;

    function loadImageWithBackup( aesthetic : string, backup : string ) {
        try {
            return getAestheticImageLink(aesthetic, 'sports').then(url=>loadImageBinary(url+"?mode=f").then( r=>{ 
                if(r){ return r; }
                return loadImageBinary(backup);
            }));
        } catch(e : any) {
            return loadImageBinary(backup);
        }
    }

    let melImageLeft = loadImageWithBackup(melAestheticLeft, '/assets/images/sports_placeholder.png');
    let melImageRight = loadImageWithBackup(melAestheticRight, '/assets/images/sports_placeholder.png');

    function onRiveEventReceived(riveEvent : RiveEvent) {
        const eventData = (riveEvent.data) as RiveEventPayload;
        if (eventData.type === RiveEventType.General) {
            console.log("Event name", eventData.name);
        }
    }

    const dispatch = createEventDispatcher();
    
    function onRiveStopped() {
        dispatch("rive-stopped");
    }

    Promise.all( [melImageLeft, melImageRight, riveData?.loading] ).then( ()=>{
        dispatch("rive-ready");
    });

    function processImage(asset : any) : (img:any)=>void {
        return (imgf:()=>ArrayBuffer) =>{
            const img = imgf();
            if( img ) {
                asset.decode( new Uint8Array( img ))
            }
        }
    }

    onMount(async () => {
        if( !riveData ) return;
        const layout = new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        });
        const mscene = riveData.mscene.deref();
        if( mscene )
            mscene.hasIntro = true;

        const rivefiledata = await getRiveFile(riveData);
        if( !rivefiledata ) return;
        riveInstance = new Rive({
            buffer: rivefiledata(),
            canvas: canvas,
            layout: layout,
            animations: 'introAnimation',
            autoplay: false,
            stateMachines: undefined, // ALT: 'bumpy', 'Motion'
            onAdvance: (e: any) => {
                riveTime += e.data;
                const mscene = riveData.mscene.deref();
                if( mscene && riveTime > 1 )
                    mscene.introReady = true;
            },
            onLoad: () => {
                if( !riveInstance ) return;
                riveInstance.resizeDrawingSurfaceToCanvas();
                //riveInstance.enableFPSCounter();
                riveInstance.setTextRunValue("eventName", melympicsEventName);
                riveInstance.setTextRunValue("leftName", melNameLeft);
                riveInstance.setTextRunValue("rightName", melNameRight);
                riveInstance.on(EventType.RiveEvent, onRiveEventReceived);
                //console.log('Rive Contents:', riveInstance.contents);
                riveTime = 0;
            },
            assetLoader: (asset : any, bytes : any) => {
                if (asset.isImage) {
                    if (asset.name === 'left_placeholder') {
                        melImageLeft.then( processImage(asset) )
                    } else if (asset.name === 'right_placeholder') {
                        melImageRight.then( processImage(asset) );
                    } else {
                        getRiveImage(riveData, asset.name).then( processImage(asset) );
                    }
                    return true;
                } else {
                return false;
                }
            },
        });
        
        window.addEventListener(
            "resize",
            () => {
                riveInstance?.resizeDrawingSurfaceToCanvas();
            },
            false
        );
    });

    onDestroy(() => {
        if( !riveInstance ) return;
        console.log( "Race Intro Destroy");
        riveInstance.reset();
        riveInstance.cleanup();
        riveInstance = undefined;
    });

    $: {
        if (showContent&&riveInstance) {
            Promise.all( [melImageLeft, melImageRight, riveData?.loading] ).then( ()=>{
                if( !riveInstance ) return;
                riveInstance.play(); // for use when we want to default autoplay off and manually trigger play start
                riveInstance.on(EventType.Stop, onRiveStopped);
            });
        }
    }
</script>

<canvas id="rive-canvas" bind:this={canvas}></canvas>

<style>
    #rive-canvas {
        position: absolute;
        height: 100%;
        width: 100%;
    }
</style>