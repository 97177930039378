<div class="footer sans-serif">
    <a href='https://ludaprojects.com/privacy' target='_blank'>PRIVACY</a>
    &nbsp;
    <a href='https://ludaprojects.com/mels/terms' target='_blank'>TERMS</a>
    &nbsp;
    <a href='https://discord.gg/jVej4pJQwc' target='_blank'>DISCORD</a>
</div>

<style>
    .sans-serif {
        font-family: 'Titillium Web', sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        font-variation-settings: 'wdth' 100;
    }

    .footer {
        position: absolute;
        display: block;
        bottom: 0px;
        left: -10px;
        width: 100vw;
        padding: 10px;
        text-align: center;
        background-color: rgba(201, 201, 201, 0.65);
    }

    .footer a {
        text-decoration: none;
        color: white;
        font-size: 13px;
        font-weight: 400;
        opacity: 1;
    }

    /* Media query for mobile */
    /* (keep in sync with media query in ui.css */
    @media screen and (orientation: portrait) {
        .footer a {
            -webkit-text-size-adjust: 100%;
            font-size: 15px;
        }
    }

    @media screen and (max-width: 768px) and (orientation: landscape) {
        .footer a {
            -webkit-text-size-adjust: 100%;
            font-size: 15px;
        }
    }
</style>
