<script lang="ts" context="module">
    export type SandboxData = {
        image_url: string;
        name: string;
        display_name: string;
        category: string;
        promote: boolean;
    };
</script>
<script lang="ts">
    import { onDestroy, onMount } from 'svelte';
    import BuilderSettings from '@/components/scenes/editor/BuilderSettings.json';

    export let sandbox: SandboxData;
    let imgRef: HTMLImageElement;
    let iObserver: IntersectionObserver;

    const loadImage = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target as HTMLImageElement;
                
                img.src = img.dataset.src as string;

                img.onload = () => {
                    img.style.visibility = 'visible';
                    img.style.opacity = '1';
                }

                img.onerror = () => {
                    img.src = "/assets/playgrounds/playground_placeholder.png";
                }

                observer.unobserve(entry.target);
            }
        });
    }

    onMount(() => {
        iObserver = new IntersectionObserver(loadImage, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });

        if (imgRef) iObserver.observe(imgRef);
    });

    onDestroy(()=>{
        if( imgRef && iObserver )
            iObserver.unobserve(imgRef);
    })

    function getUrl(name: string): string {
        const curParam = new URLSearchParams(window.location.search);
        curParam.delete("playground");
        curParam.delete('p');

        // special handling for loading training sandbox
        if (name === BuilderSettings.trainingLevel) return `/training?${curParam.toString()}`;

        curParam.set('p', name);
        return `/play?${curParam.toString()}`;
    }
</script>

<div class="entry">
    <div>
        <a href={getUrl(sandbox.name)}>
            <img 
                bind:this={imgRef} 
                data-src={sandbox.image_url} 
                src="/assets/playgrounds/playground_placeholder.png" 
                alt=""
                class="entry-image reveal" 
                style="visibility: hidden; opacity: 0;"
            >
        </a>    
    </div>
    <div class="entry-title sans-serif">{sandbox.display_name}<br/><span class="entry-subtitle">#{sandbox.category}</span></div>
    {#if sandbox.promote}
        <div><img class="entry-new-icon" src="/assets/icons/star.png" alt=""></div>
    {/if}
</div>

<style>
    .sans-serif {
        font-family: 'Titillium Web', sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        font-variation-settings: 'wdth' 100;
    }

    .entry {
        position: relative;
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        background-image: url('/assets/playgrounds/playground_placeholder.png');
        background-size: cover;
        box-shadow: 1px 1px 4px gray;
    }
    
    .entry-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .entry-title {
        position: absolute;
        bottom: 10px;
        left: 10px;
        font-family: monospace;
        color: white;
        opacity: 1.0;
        font-size: 14px;
    }

    .entry-subtitle {
        opacity: 0.6;
    }
    
    .entry-new-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        opacity: 1;
    }
</style>
    