<script lang="ts">
    import { type Race, convertSecondsToStopwatch} from "@/data/prefabs/Race.js";
    import PostRaceOverlay from "@/elements/RaceElements/PostRaceOverlay.svelte";
    import { MelGameEvent } from "@/events/MelGameEvent.js";
    import { sleep } from "@/helpers/functions.js";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import RacePopup from "@/elements/RaceElements/RacePopup.svelte";
    import RaceIntro from "@/elements/RaceElements/RaceIntro.svelte";
    import { truncateNameEnd } from "@/helpers/race.js";
    import { getScores, clearScores } from "@/helpers/score_reporting.js";
    import RaceOutro from "@/elements/RaceElements/RaceOutro.svelte";
    import type { RiveAssets } from "@/helpers/assets.js";
    export var race : Race;
    export var post_race : boolean = false;
    export var riveIntroData: RiveAssets | undefined = undefined;
    export var riveOutroData: RiveAssets | undefined = undefined;
    var next_page_countdown : boolean = false;

    // temp for getting WR
    let boards = race.getLeaderboards();

    type DataEntry = {
        mel_id : string,
        score: number,
        recorded_at: string
    }
    const maxEntries = 1;
    var rows : DataEntry[] =[];

    $: boards[0] && getScores(boards[0], maxEntries, true).then( (arg)=>{
        const s = arg();
        const diff = Math.max(maxEntries - s.length,0);
        rows = [...s, ...Array(diff).fill({mel_id:"", score: "", recorded_at:""})]; 
        clearScores();
    } );

    enum RaceUiMode {
        none,
        firstPlacard,
        secondPlacard,
        timer,
    }

    enum PopupOrder {
        first = 'firstPopup',
        second = 'secondPopup'
    }

    enum PopupClass {
        finish = 'finishPopup',
        dq = 'DQPopup'
    }

    type PopupData = {
        order: PopupOrder,
        popupClass: PopupClass,
        name: string | undefined,
        imageIndex: number,
        finishTime?: number
    }

    var raceTimeText = '0.00';
    var raceTime = 0;
    export var showIntro: boolean = true;
    export var showRive: boolean = false;
    var showLeaderboards: boolean = false;
    var mode: RaceUiMode = RaceUiMode.none;
    var dqName: string = '';
    var finishName: string = '';
    var finishTime = 0;
    var timer : ReturnType<typeof setInterval>;
    let melIndex = 0;
    let showFirstPopup: boolean = false;
    let showSecondPopup: boolean = false;
    let popupIndex = 0;
    let popupDataArray: PopupData[] = [];
    let showButtons = false;

    $: if (post_race) {
        resetPopups();
        // temporarily prevent showing leaderboard
        // setTimeout( ()=>{
        //     showLeaderboards = true;
        // }, 500);
    }

    const dispatch = createEventDispatcher();
    function onNextRace() {
        showLeaderboards = false;
        next_page_countdown = false;
        console.log( "Next Race Overlay");
        dispatch('next-race');
    }
    function onRematch() {
        showLeaderboards = false;
        next_page_countdown = false;
        showButtons = false;
        dispatch('rematch');
    }

    function onError() {
        dispatch('error');
    }

    function onRivePlay() {
        dispatch('rive-play');
    }

    function onRiveIntroReady() {
        dispatch('rive-intro-ready');
    }

    async function enterSandboxProcessor() {
        if (race.prefabDisplayName === 'wrestling') return;
        await sleep(6.0);
        mode = RaceUiMode.firstPlacard;
        await sleep(3.0);
        mode = RaceUiMode.none;
        await sleep(1);
        mode = RaceUiMode.secondPlacard;
        //await sleep(3.7);
        //mode = RaceUiMode.none;
    };

    async function startCountdownProcessor() {
        mode = RaceUiMode.timer;
        clearInterval(timer);
        timer = setInterval( ()=>{
            raceTimeText = convertSecondsToStopwatch(race.raceTime); 
            raceTime = race.raceTime;
        }, 100 ) 
    };
    function stopCountdownProcessor() {
        clearInterval(timer);
    }
    async function disqualificationProcessor(e: MelGameEvent) {
        if (!e.mel) {
            console.error("DisqualificationProcessor Error: e.mel not present", e);
            return;
        }

        const melImageIndex = getMelIndex(e.mel.name);

        if (!(melImageIndex === 0 || melImageIndex === 1)) {
            console.error("DisqualificationProcessor Error: Invalid melImageIndex for", e.mel.name);
            return;
        }

        dqName = e.mel?.melJSONConfig?.display_name??'';
        popupIndex = melIndex;

        let popupData: PopupData = {
            order: PopupOrder.first,
            name: truncateNameEnd(dqName),
            imageIndex: melImageIndex,
            popupClass: PopupClass.dq
        };

        if (melIndex === 0) {
            showFirstPopup = true;
        } else if (melIndex === 1) {
            stopCountdownProcessor();
            popupData.order = PopupOrder.second;
            showSecondPopup = true;
        }
        popupDataArray[melIndex] = popupData;
        melIndex++;
    };
    async function finishProcessor(e: MelGameEvent) {
        console.log(e);

        if (!e.mel) {
            console.error("FinishProcessor Error: e.mel not present", e);
            return;
        }

        const melImageIndex = getMelIndex(e.mel.name);

        if (!(melImageIndex === 0 || melImageIndex === 1)) {
            console.error("FinishProcessor Error: Invalid melImageIndex for", e.mel.name);
            return;
        }

        finishName = e.mel.melJSONConfig?.display_name??'';
        finishTime = e.raceTime??0;

        let popupData: PopupData = {
            order: PopupOrder.first,
            name: truncateNameEnd(finishName),
            imageIndex: melImageIndex,
            popupClass: PopupClass.finish,
            finishTime: finishTime
        };

        if (melIndex === 0) {
            showFirstPopup = true;
        } else if (melIndex === 1) {
            stopCountdownProcessor();
            // override displayed time with true race time due to frequent deltas
            raceTimeText = convertSecondsToStopwatch(finishTime);
            popupData.order = PopupOrder.second;
            showSecondPopup = true;
        }
        popupDataArray[melIndex] = popupData;
        melIndex++;
    };

    function processEvent(e : Event) {
        if (!(e instanceof MelGameEvent)) return;
        switch( e.gameType ) {
            case 'SYSTEM_ENTER_SANDBOX':
                enterSandboxProcessor();
                break;
            case 'START_COUNTDOWN':
                startCountdownProcessor();
                break;
            case 'DISQUALIFIED':
                disqualificationProcessor(e);
                break;
            case 'FINISH_LINE_UI':
                finishProcessor(e);
                break;
        }
    }

    function resetPopups() {
        mode = RaceUiMode.none;
        melIndex = 0;
        showFirstPopup = false;
        showSecondPopup = false;
        popupIndex = 0;
        popupDataArray = [];
    }

    function processRiveStop() {
        if( !showIntro ) return;
        showIntro=false; 
        race.fireEvent(new MelGameEvent('RIVE_INTRO_STOPPED', race));
    }

    function getMelIndex(name: string | undefined) {
        if (!name)
            return;

        let charAtIndex = name.charAt(3);

        if (charAtIndex === '0' || charAtIndex === '1')
            return Number(charAtIndex);
        else
            return;
    }

    onMount( ()=>{
        document.addEventListener('mel-game-event', processEvent );
    });
    onDestroy( ()=>{
        document.removeEventListener('mel-game-event', processEvent );
        clearInterval(timer);
    });
</script>

<div id="raceOverlayContainer" class="sans-serif">
    {#if !post_race}
        <div id="inRaceContainer">
            {#if mode === RaceUiMode.secondPlacard}
            <div id="mel0Placard" class="slantRightEdge placard">
                <div class="nameContainer alignSelfCenter nameLeft">
                    <div class="name">
                        {race.getLeftMelName()}
                    </div>
                </div>
            </div>
            {/if}
            {#if mode===RaceUiMode.firstPlacard}
            <div id="mel1Placard" class="slantLeftEdge placard">
                <div class="nameContainer alignSelfCenter nameRight">
                    <div class="name">
                        {race.getRightMelName()}
                    </div>
                </div>
            </div>
            {/if}
            {#if mode === RaceUiMode.timer}
            <div id="timeContainer">
                <div id="timer" class="slantLeftEdge">
                    <div class="alignSelfCenter">
                        {raceTimeText}
                    </div>
                </div>
                <div id="worldRecord" class="slantRightEdge">
                    <div class="alignSelfCenter">
                        <span class="goldHighlight">WR</span> {convertSecondsToStopwatch(rows[0].score)}
                    </div>
                </div>
            </div>
            {/if}

            {#if showFirstPopup}
                <RacePopup race={race} popupData={popupDataArray[0]} />
            {/if}
            {#if showSecondPopup}
                <RacePopup race={race} popupData={popupDataArray[1]} />
            {/if}
        </div>
    {/if}
    {#if riveIntroData && showIntro } 
        <RaceIntro riveData={riveIntroData} on:rive-ready={onRiveIntroReady} melympicsEventName={race.eventName} on:rive-stopped={processRiveStop} melAestheticLeft={race.getLeftMelAesthetic()} melNameLeft={race.getLeftMelName()} melAestheticRight={race.getRightMelAesthetic()} melNameRight={race.getRightMelName()} showContent={showRive} ></RaceIntro>
    {/if}
    {#if riveOutroData && post_race}
        <div class="outroContainer">
            {#if showButtons}
                <PostRaceOverlay race={race} on:next-race={onNextRace} on:rematch={onRematch}></PostRaceOverlay>
            {/if}
            <RaceOutro riveData={riveOutroData} on:rive-play={onRivePlay} melympicsEventName={race.eventName} startsSwapped={race.getStartsSwapped()} raceData={race.realtimeData} on:rive-error={onError} on:rive-stopped={()=>{console.log("rstop"); showButtons=true}} melAestheticLeft={race.getLeftMelAesthetic()} melNameLeft={race.getLeftMelName()} melAestheticRight={race.getRightMelAesthetic()} melNameRight={race.getRightMelName()} showContent={true}></RaceOutro>
        </div>
        <!-- restore below for proceeding to leaderboard after race results -->
        <!-- <RaceOutro riveData={riveOutroData} on:rive-stopped={()=>{next_page_countdown=true;post_race=false;}} melAestheticLeft={race.getLeftMelAesthetic()} melNameLeft={race.getLeftMelName()} melAestheticRight={race.getRightMelAesthetic()} melNameRight={race.getRightMelName()} showContent={true}></RaceOutro> -->
    {/if}
</div>

<style>
    .sans-serif {
        font-family: 'Titillium Web', sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        font-variation-settings: 'wdth' 100;
    }

    .outroContainer {
        position: relative;
        width: 100%;
        height: 100%;
    }

    #raceOverlayContainer {
        color: #222;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
    }

    #inRaceContainer {
        color: #f1f1f1;
    }

    @keyframes slideInAndOutFromLeft {
        0% {
            transform: translateX(-100%);
        }
        20% {
            transform: translateX(0);
        }
        80% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes slideInAndOutFromRight {
        0% {
            transform: translateX(100%);
        }
        20% {
            transform: translateX(0);
        }
        80% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(100%);
        }
    }

    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    /* second/left placard */
    #mel0Placard {
        left: 0;
        justify-content: start;
        padding-left: 15px;
        padding-right: 40px;

        animation: slideInAndOutFromLeft 2s forwards;
        animation-timing-function: 
            cubic-bezier(0, 0, 0.2, 1), /* ease-out for slide-in */
            linear, /* linear for lingering */
            cubic-bezier(0.4, 0, 1, 1); /* ease-in for slide-out */
        transform: translateX(-100%);
    }

    /* first/right placard */
    #mel1Placard {
        right: 0;
        justify-content: end;
        padding-right: 15px;
        padding-left: 40px;

        animation: slideInAndOutFromRight 2s forwards;
        animation-timing-function: 
            cubic-bezier(0, 0, 0.2, 1), /* ease-out for slide-in */
            linear, /* linear for lingering */
            cubic-bezier(0.4, 0, 1, 1); /* ease-in for slide-out */
        transform: translateX(100%);
    }

    .placard {
        display: flex;
        position: absolute;
        bottom: 45%;
        height: 35px;
        font-weight: 600;
        min-width: 40px;
        text-transform: none;
    }

    .nameContainer {
        display: flex;
        flex-direction: column;
    }

    .nameLeft {
        justify-content: start;
        align-items: start;
    }

    .nameRight {
        justify-content: end;
        align-items: end;
    }

    #timer {
        position: absolute;
        bottom: 120px;
        right: 0px;
        min-width: 20px;
        height: 35px;
        display: flex;
        justify-content: end;
        padding-right: 15px;
        padding-left: 40px;
        font-weight: 600;

        animation: slideInFromRight 0.5s forwards;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1); /* ease-out for slide-in */
        transform: translateX(100%);
    }

    #worldRecord {
        position: absolute;
        bottom: 120px;
        left: 0px;
        min-width: 50px;
        height: 35px;
        display: flex;
        justify-content: start;
        padding-left: 15px;
        padding-right: 40px;
        font-weight: 600;

        animation: slideInFromLeft 0.5s forwards;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1); /* ease-out for slide-in */
        transform: translateX(-100%);
    }

    .alignSelfCenter {
        align-self: center;
    }

    .goldHighlight {
        color: gold;
    }

    .slantLeftEdge {
        background: linear-gradient(105deg, transparent 30px, rgba(0,0,0,0.65) 30px);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .slantRightEdge {
        background: linear-gradient(285deg, transparent 30px, rgba(0,0,0,0.65) 30px);;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* @media screen and (max-width: 1024px) and (orientation: landscape) {
    } */
</style>
