import { Race } from '@/data/prefabs/Race.js';
import { MelSveltePage } from '@/elements/MelSveltePage.js';
import RaceOverlay from '@/elements/RaceElements/RaceOverlay.svelte';

export class RaceOverlayHTML extends MelSveltePage<RaceOverlay> {
    constructor() {
        var props: any = {};
        const playground = globalThis.scene.system?.playground;
        if (playground) {
            const races = playground.getChildren<Race>((c): c is Race => c instanceof Race);
            if (races.length > 0) {
                props.race = races[0];
            }
        }
        super(RaceOverlay, props);
        this.allowClickThrough = true;
    }
}

if (!customElements.get('race-overlay')) {
    customElements.define('race-overlay', RaceOverlayHTML);
}
