<script lang="ts">
    import TopNav from '@/elements/Navigation/TopNav.svelte';
    import Social from '@/elements/Layout/Social.svelte';
</script>

<TopNav/>
<Social/>
<div class="overlay-menu">
    <div class="Spacer"></div>
    <button class="overlay-category">Help</button>
    <div class="Spacer"></div>
</div>
<div class="containerCenter sans-serif">
    <div class="container">
        <iframe title="help" src="https://docs.google.com/document/d/e/2PACX-1vSsavGQ-S7oq3rLuehrbde6NiJE5PS6kqBri-5zqdzrTJahgSurekHW7K8bA3FgoThuOetSqPJZhqcU/pub?embedded=true"></iframe>
    </div>
</div>

<style>
    .overlay-menu {
        height: 0px;
        width: 100%;
        background-color: rgb(201, 201, 201);
        display: flex;
        flex-direction: row;
    }
    
    .overlay-category {
        color: white;
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 18px;
        font-family: 'Titillium Web', sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        opacity: 1;
        background-color: transparent;
        border: none;
        cursor: default;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: 0px;
        margin-top: 50px;
    }

    .sans-serif {
        font-family: 'Titillium Web', sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        font-variation-settings: 'wdth' 100;
    }

    .containerCenter {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgb(201, 201, 201);
        touch-action: auto;
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        -webkit-overflow-scrolling: touch;
    }

    .containerCenter::-webkit-scrollbar {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 820px;
        padding: 1rem;
        color: #fff;
    }

    .Spacer {
        flex-grow: 1;
        pointer-events: none;
    }
</style>
