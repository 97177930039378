<script lang="ts">
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    import { Rive, Layout, Fit, Alignment, EventType, type RiveParameters, type Event as RiveEvent, type RiveEventPayload, RiveEventType } from '@rive-app/canvas';
    import { getRiveFile, getRiveImage, loadImageBinary, type RiveAssets } from '@/helpers/assets.js';
    import type { RaceRealtimeMelData } from "@/data/prefabs/Race.js";
    import { convertSecondsToStopwatch } from "@/data/prefabs/Race.js";
    import { getAestheticImageLink } from '@/helpers/mel_cloud_storage';

    let canvas : HTMLCanvasElement;
    let riveInstance : Rive | undefined = undefined;
    export let showContent : boolean = false;
    export let riveData: RiveAssets | undefined = undefined;
    export var raceData: RaceRealtimeMelData[] = [];
    export let startsSwapped: boolean = false;
    export let melympicsEventName: string = '';
    let placeLeft = "#0";
    let placeRight = "#0";
    let timeLeft = "0.00";
    let timeRight = "0.00";
    let chaosLeft = "0";
    let chaosRight = "0";
    let faultsLeft = "0";
    let faultsRight = "0";
    let riveError : boolean = false;
    let riveTimeout = 8;
    let backupTimer : ReturnType<typeof setTimeout> | undefined = undefined;
    export let melNameLeft = '';
    export let melNameRight = '';
    export let melAestheticRight = '';
    export let melAestheticLeft = '';

    function loadImageWithBackup( aesthetic : string, backup : string ) {
        try {
            return getAestheticImageLink(aesthetic, 'sports').then(url=>loadImageBinary(url+"?mode=f").then( r=>{ 
                if(r){ return r; }
                return loadImageBinary(backup);
            }));
        } catch(e : any) {
            return loadImageBinary(backup);
        }
    }

    let melImageLeft = loadImageWithBackup(melAestheticLeft, '/assets/images/sports_placeholder.png');
    let melImageRight = loadImageWithBackup(melAestheticRight, '/assets/images/sports_placeholder.png');

    function onRiveEventReceived(riveEvent : RiveEvent) {
        const eventData = (riveEvent.data) as RiveEventPayload;
        if (eventData.type === RiveEventType.General) {
            console.log("Event name", eventData.name);
        }
    }

    function processImage(asset : any) : (img:any)=>void {
        return (imgf:()=>ArrayBuffer) =>{
            const img = imgf();
            if( img ) {
                asset.decode( new Uint8Array( img ))
            }
        }
    }

    const dispatch = createEventDispatcher();
    
    function onRiveStopped() {
        riveInstance?.off(EventType.Stop, onRiveStopped);
        if( !riveError )
            dispatch("rive-stopped");
    }

    function onRiveError() {
        riveError = true;
        dispatch("rive-error");
    }

    onMount(async () => {
        if( !riveData ) { onRiveError(); return; }
        const layout = new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        });

        backupTimer = setTimeout( ()=> onRiveError(), riveTimeout * 1000);

        // extract race data for displaying in Rive
        if ( !raceData ) { onRiveError(); return; }
        
        const leftMelRaceData = raceData[startsSwapped ? 1 : 0];
        if (leftMelRaceData.winner) {
            placeLeft = "#1";
        } else if (leftMelRaceData.disqualified) {
            placeLeft = "DQ";
        } else {
            placeLeft = "#2";
        }

        const rightMelRaceData = raceData[startsSwapped ? 0 : 1];
        if (rightMelRaceData.winner) {
            placeRight = "#1";
        } else if (rightMelRaceData.disqualified) {
            placeRight = "DQ";
        } else {
            placeRight = "#2";
        }

        // if not a DQ, convert finish time to stopwatch format and remove any leading 0
        timeLeft = leftMelRaceData.disqualified ? "DQ" : convertSecondsToStopwatch(leftMelRaceData.finishTime).replace(/^0/, '');
        timeRight =  rightMelRaceData.disqualified ? "DQ" : convertSecondsToStopwatch(rightMelRaceData.finishTime).replace(/^0/, '');

        chaosLeft = leftMelRaceData.chaos.toFixed(0);
        chaosRight = rightMelRaceData.chaos.toFixed(0);

        faultsLeft = leftMelRaceData.faults.toString();
        faultsRight = rightMelRaceData.faults.toString();

        const rivefiledata = await getRiveFile(riveData);
        if( !rivefiledata ) { onRiveError(); return; }
        const rParams : RiveParameters = {
            buffer: rivefiledata(),
            canvas: canvas,
            layout: layout,
            animations: 'resultsAnimation',
            autoplay: false,
            stateMachines: undefined, // ALT: 'bumpy', 'Motion'
            onLoad: () => {
                if( !riveInstance ) { onRiveError(); return; }
                riveInstance.resizeDrawingSurfaceToCanvas();
                //riveInstance.enableFPSCounter();
                riveInstance.setTextRunValue("eventName", melympicsEventName);
                riveInstance.setTextRunValue("leftName", melNameLeft);
                riveInstance.setTextRunValue("rightName", melNameRight);
                riveInstance.setTextRunValue("placeLeft", placeLeft);
                riveInstance.setTextRunValue("placeRight", placeRight);
                riveInstance.setTextRunValue("timeLeft", timeLeft);
                riveInstance.setTextRunValue("timeRight", timeRight);
                riveInstance.setTextRunValue("chaosLeft", chaosLeft);
                riveInstance.setTextRunValue("chaosRight", chaosRight);
                riveInstance.setTextRunValue("faultsLeft", faultsLeft);
                riveInstance.setTextRunValue("faultsRight", faultsRight);

                riveInstance.on(EventType.RiveEvent, onRiveEventReceived);
                //console.log('Rive Contents:', riveInstance.contents);
            },
            onLoadError: ()=>{
                onRiveError();
            },
            onPlay: ()=>{
                clearTimeout(backupTimer);
                dispatch("rive-play");
            },
            assetLoader: (asset:any, bytes:any) => {
                if (asset.isImage) {
                    if (asset.name === 'left_placeholder') {
                        melImageLeft.then( processImage(asset) );
                    } else if (asset.name === 'right_placeholder') {
                        melImageRight.then( processImage(asset) );
                    } else {
                        getRiveImage(riveData, asset.name).then( processImage(asset) );
                    }
                    return true;
                } else {
                return false;
                }
            },
        }
        riveInstance = new Rive( rParams );
        
        window.addEventListener(
            "resize",
            () => {
                riveInstance?.resizeDrawingSurfaceToCanvas();
            },
            false
        );
    });

    onDestroy(() => {
        if( !riveInstance ) return;
        console.log( "Race Outro Destroy");
        riveInstance.reset();
        riveInstance.cleanup();
        riveInstance = undefined;
    });

    $: {
        if (showContent&&riveInstance) {
            const loadingTimout = setTimeout( ()=>{ onRiveError() }, 10000 );
            Promise.all( [melImageLeft, melImageRight, riveData?.loading] ).then( ()=>{
                clearTimeout(loadingTimout);
                if( !riveInstance ) { onRiveError(); return; }
                riveInstance.play(); // for use when we want to default autoplay off and manually trigger play start
                riveInstance.on(EventType.Stop, onRiveStopped);
            });
        }
    }
</script>

{#if showContent}
    <canvas id="rive-canvas" bind:this={canvas}></canvas>
{/if}

<style>
    #rive-canvas {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 200;
    }
</style>
