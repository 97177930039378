<div class="top-right-action">
    <button id="help" class="icon-text-button">
        <a href="/help" target='_blank'>
            <img id="info-icon" src="/assets/icons/help.png" alt="Help" />
        </a>
    </button>
</div>

<style>
    .icon-text-button {
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 15px;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        border: none;
    }

    #info-icon {
        height: auto;
        width: 30px;
        display: block;
    }
</style>