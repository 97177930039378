import BuilderUISvelte from '@/components/scenes/editor/BuilderUISvelte.svelte';
import { MelSveltePage } from '@/elements/MelSveltePage.js';

export type CarouselEntry = {
    bg_url: string;
    link: () => void;
    enabled?: () => boolean;
};

export class BuilderUi extends MelSveltePage<BuilderUISvelte> {
    constructor() {
        super(BuilderUISvelte);
        this.allowClickThrough = true;
    }
    toggleCarousel(enabled: boolean) {
        this.comp?.$$set?.({ carouselon: enabled });
    }
    toggleInfoButton(enabled: boolean) {
        this.comp?.$$set?.({ infobuttonon: enabled });
    }
    toggleUndoRedo(enabled: boolean) {
        this.comp?.$$set?.({ undoredoon: enabled });
    }
    setCarouselConfig(carConfig: CarouselEntry[]) {
        this.comp?.$$set?.({ carousel_config: carConfig });
    }
}

if (!customElements.get('builder-ui')) {
    customElements.define('builder-ui', BuilderUi);
}
