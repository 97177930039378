<script lang="ts">
    import TopNav from '@/elements/Navigation/TopNav.svelte';
    import BuilderUICarousel from "./BuilderUICarousel.svelte";
    export var carouselon = false;
    export var undoredoon = false;
    export var infobuttonon = false;
    export var carousel_config : CarouselEntry[] = [];
</script>

<div style="pointer-events:auto">
    <TopNav/>
    <div class="BuilderCarousel" class:disabled={!carouselon}>
        <BuilderUICarousel carousel_config={carousel_config}></BuilderUICarousel>
    </div>
    <div class="Builder UndoRedo" class:viewable={undoredoon}>
        <div class="PrevArrowBtn disabled"></div>
        <div class="NextArrowBtn disabled"></div>
        <div class="EraseBtn disabled" ></div>
    </div>
    <div class="BuilderInfoIconContainer">
        <button id="InfoButton" class:hidden={!infobuttonon}>
            <img src="/assets/icons/help.png" alt="Info">
        </button>
    </div>
</div>

<style>
button {
    display: block;
    border: none;
    cursor: pointer;
    background: none;
    z-index: 1;

    /** Hide the built-in outline when clicking buttons. */
    outline: none;
}

button img {
    display: block;
    width: 30px;
}

.BuilderCarousel {
    position: absolute;
    bottom: 16px;
    width: 100%;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
}

.BuilderCarousel.disabled {
    transform: translate(0, 110%);
}

.EraseBtn {
    width: 30px;
    height: 30px;
    margin-left: 25px;
    margin-top: -1px;
    background-image: url('assets/icons/erase.svg');
    opacity: 1;
    cursor: pointer;
}

.PrevArrowBtn {
    width: 30px;
    height: 30px;
    margin-left: 0px;
    margin-top: 0px;
    background-image: url('assets/icons/prev_icon.svg');
    opacity: 1;
    cursor: pointer;
}

.NextArrowBtn {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-top: 0px;
    background-image: url('assets/icons/next_icon.svg');
    opacity: 1;
    cursor: pointer;
}

.EraseBtn.disabled,
.PrevArrowBtn.disabled,
.NextArrowBtn.disabled {
    opacity: 0.3;
    cursor: default;
}

.UndoRedo {
    position: absolute;
    bottom: 29px;
    right: 17px;
    height: 30px;
    display: flex;
    flex-direction: row;
    visibility: hidden;
}

.UndoRedo.viewable {
    visibility: visible;
}

.BuilderInfoIconContainer {
    height: 30px;
    width: 100%;
    padding-bottom: 15px;
    display: flex;
    justify-content: end;
    cursor: pointer;
}

#InfoButton {
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    right: 14px;
    top: 15px;
    padding-left: 0;
    padding-right: 0;
}

</style>