import ShowcaseOverlay from '@/elements/Showcase/ShowcaseOverlay.svelte';
import { MelSveltePage } from '@/elements/MelSveltePage.js';

export class MelShowcaseOverlay extends MelSveltePage<ShowcaseOverlay> {
    constructor() {
        super(ShowcaseOverlay);
    }

    protected override setupComponent(): void {
        this.comp?.$on('close-overlay', () => {
            this.close();
        });
    }

    override getCSSFiles(): string[] | undefined {
        return ['./css/showcase.css'];
    }

    override connectedCallback(): void {
        super.connectedCallback();
        const mIndex = parseInt(this.attributes.getNamedItem('melindex')?.value ?? '0');
        this.comp?.$set({
            melIndex: mIndex,
        });
    }
}

if (!customElements.get('mel-showcase-overlay')) {
    customElements.define('mel-showcase-overlay', MelShowcaseOverlay);
}
