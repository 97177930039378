import Sandboxes from '@/elements/Sandboxes/Sandboxes.svelte';
import { MelSveltePage } from '@/elements/MelSveltePage.js';

export class MelSandboxes extends MelSveltePage<Sandboxes> {
    constructor() {
        super(Sandboxes);
    }

    override getCSSFiles(): string[] | undefined {
        return ['./css/sandbox-menu.css'];
    }
}

if (!customElements.get('mel-sandboxes')) {
    customElements.define('mel-sandboxes', MelSandboxes);
}
