function truncateName(name: string | undefined) {
    if (!name) return '';

    if (name[name.length - 6] === '.') return name.slice(5, -6);
    else return name.slice(5);
}

function truncateNameStart(name: string | undefined) {
    if (!name) return '';

    const prefix = name.slice(0, 5).toUpperCase();

    if (prefix === 'MEL0_' || prefix === 'MEL1_') return name.slice(5);
    else return name;
}

function truncateNameEnd(name: string | undefined) {
    if (!name) return '';

    if (name[name.length - 6] === '.') return name.slice(0, -6);
    else return name;
}

function getNameHash(name: string | undefined) {
    if (!name) return '';

    if (name[name.length - 6] === '.') return '#' + name.slice(-5);
    else return '';
}

export { truncateName, truncateNameStart, truncateNameEnd, getNameHash };
