import Dashboard from '@/elements/Dashboard/Dashboard.svelte';
import { MelSveltePage } from '@/elements/MelSveltePage.js';

export class MelDashboard extends MelSveltePage<Dashboard> {
    constructor() {
        super(Dashboard);
    }

    override getCSSFiles(): string[] | undefined {
        return ['./css/dashboard.css'];
    }
}

if (!customElements.get('mel-dashboard')) {
    customElements.define('mel-dashboard', MelDashboard);
}
